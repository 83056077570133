import { ChevronRightIcon } from "@enzymefinance/icons/solid";
import { Badge, Icon, NumberDisplay, Skeleton, Tooltip } from "@enzymefinance/ui";
import classNames from "classnames";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency, useCurrencySlug } from "components/settings/Settings";
import { VaultIcon } from "components/vault/VaultIcon";
import { VaultSparkline } from "components/vault/VaultSparkline";
import type { VaultListItem } from "queries/backend";
import type { ReactNode } from "react";

import dayjs from "dayjs";
import type { LinkProps } from "../routing/Link";
import { Link } from "../routing/Link";

interface VaultCardProps {
  error?: string;
  appearance?: "default" | "primary";
  loading?: boolean;
  to?: LinkProps["to"];
  vault?: VaultListItem;
  width?: "lg" | "sm";
}

const qualifyAsNewPeriod = dayjs().subtract(2, "week");

export function VaultCard({ appearance = "default", error, loading = false, to, vault, width = "sm" }: VaultCardProps) {
  const { deployment } = useNetwork();
  const currency = useCurrency();
  const currencySlug = useCurrencySlug();

  const perShareId = vault?.address ? `${vault.address}-per-share` : undefined;
  const avgMonthlyReturnId = vault?.address ? `${vault.address}-avg-monthly-return` : undefined;
  const aumId = vault?.address ? `${vault.address}-aum` : undefined;
  const depositorsId = vault?.address ? `${vault.address}-depositors` : undefined;

  const classes = classNames("relative max-h-full", {
    "transition focus-visible:bg-gray-200 dark:focus-visible:bg-gray-600/50": !loading,
    "min-h-[360px]": loading,
  });
  const containerClasses = classNames("flex flex-col flex-1", {
    "justify-between max-w-full max-h-full": appearance === "primary",
    "hover:bg-gray-100 dark:hover:bg-gray-700/50": appearance !== "primary",
  });
  const titleClasses = classNames("flex relative justify-between space-x-2 items-center p-4 min-h-[104px]");
  const nameClasses = classNames("line-clamp-2 text-heading-content");
  const labelClasses = classNames("font-medium", {
    "text-primary-content": appearance === "primary",
    "text-base-content": appearance !== "primary",
  });
  const perShareClasses = classNames("flex flex-col min-w-0 font-bold text-2xl", {
    "w-full": loading,
  });
  const perShareTermClasses = classNames("text-xs whitespace-nowrap", {
    "opacity-50": appearance === "primary",
    "text-base-content ": appearance === "default",
  });
  const avgMonthlyReturnClasses = classNames("flex flex-col min-w-0 text-sm", { "w-full": loading });
  const avgMonthlyReturnTermClasses = classNames("text-xs", {
    "opacity-50": appearance === "primary",
    "text-base-content": appearance === "default",
  });
  const infoBoxWrapperClasses = classNames("flex items-center justify-between p-4", {
    "bg-base-400 group-hover:bg-gray-50 group-hover:bg-gray-700/50 transition": appearance === "default" && !loading,
    "bg-primary-dark/25 dark:bg-primary-darker/25": appearance === "primary" && !loading,
  });
  const sparklineBoxClasses = classNames("overflow-hidden rounded-md flex items-center justify-center");

  const isNew = typeof vault?.inception === "number" ? dayjs.unix(vault.inception).isAfter(qualifyAsNewPeriod) : false;

  return (
    <VaultCard.Wrapper className={classes} loading={loading} to={to ?? `/vault/${vault?.address}`}>
      {appearance === "primary" && !loading ? (
        <>
          <div className="bg-gradient-primary-reverse absolute inset-0 rounded-lg bg-gradient-to-r opacity-0 transition group-hover:opacity-20" />
          <div className="bg-gradient-primary-reverse absolute inset-0 rounded-lg bg-gradient-to-r opacity-0 transition group-focus-visible:opacity-100" />
        </>
      ) : null}
      <div className={containerClasses}>
        <div className={titleClasses}>
          <div className="flex items-center space-x-2">
            <div className="mb-2 flex-none">
              <VaultIcon id={vault?.address} deployment={deployment} size={width === "sm" ? 10 : 14} />
            </div>
            <div className="space-y-0.5">
              {loading ? (
                <Skeleton color={appearance === "primary" ? "white" : "gray"} className="h-5 w-full" />
              ) : (
                error ?? (
                  <Tooltip.Provider>
                    <Tooltip.Item as="h4" className={nameClasses}>
                      {vault?.name}
                    </Tooltip.Item>
                    <Tooltip.Panel>{vault?.name}</Tooltip.Panel>
                  </Tooltip.Provider>
                )
              )}
            </div>
          </div>
          {isNew ? (
            <Badge size="lg" appearance="primary">
              New
            </Badge>
          ) : null}
        </div>
        <dl className="overflow-hidden rounded-md p-4">
          <div className="flex items-center justify-between space-x-1 truncate text-sm">
            <dt className={labelClasses} id={aumId}>
              {loading ? "" : "AUM"}
            </dt>
            <dd aria-labelledby={aumId} className="flex min-w-0 flex-col font-bold">
              <NumberDisplay
                error={error}
                appearance="neutral"
                numberFormat={{ currency, notation: "compact" }}
                value={vault?.gavCurrent ?? undefined}
                className="text-lg"
                loading={loading}
              />
            </dd>
          </div>
          <div className="flex items-center justify-between space-x-1 truncate text-sm">
            <dt className={labelClasses} id={depositorsId}>
              {loading ? "" : "Depositors"}
            </dt>
            <dd aria-labelledby={depositorsId} className="flex min-w-0 flex-col truncate font-bold">
              <NumberDisplay
                error={error}
                appearance="neutral"
                numberFormat={{ notation: "compact" }}
                value={vault?.numberOfDepositors ?? undefined}
                className="text-lg"
                loading={loading}
              />
            </dd>
          </div>
        </dl>
        <div className={sparklineBoxClasses}>
          {vault?.address ? (
            <div className="-mx-4 flex-1">
              <VaultSparkline currency={currencySlug} id={vault.address} deployment={deployment} width={1.5} />
            </div>
          ) : null}
        </div>
        <div className={infoBoxWrapperClasses}>
          <div className="flex items-end">
            <div className="flex items-baseline space-x-1">
              <dd aria-labelledby={perShareId} className={perShareClasses}>
                <NumberDisplay
                  error={error}
                  loading={
                    loading ? (
                      appearance === "primary" ? (
                        <Skeleton color="white" className="h-3 w-3/4" />
                      ) : (
                        loading
                      )
                    ) : (
                      false
                    )
                  }
                  appearance="neutral"
                  numberFormat={{ currency }}
                  value={vault?.nsvCurrent ?? undefined}
                />
              </dd>
              <dt className={perShareTermClasses} id={perShareId}>
                {loading ? null : <span>/ share</span>}
              </dt>
            </div>
            {typeof vault?.performance24h === "number" ? (
              <div className="ml-4 flex items-baseline">
                <dd aria-label="Average Monthly Return" className={avgMonthlyReturnClasses}>
                  <NumberDisplay
                    className="mr-1"
                    error={error}
                    fallback=""
                    appearance={appearance === "primary" ? "simple" : "colorized"}
                    loading={
                      loading ? (
                        appearance === "primary" ? (
                          <Skeleton color="white" className="h-3 w-3/4" />
                        ) : (
                          loading
                        )
                      ) : (
                        false
                      )
                    }
                    numberFormat={{ signDisplay: "always", style: "percent" }}
                    value={vault.performance24h}
                  />
                </dd>
                <dt className="flex-none" id={avgMonthlyReturnId}>
                  {loading ? null : error ? null : <span className={avgMonthlyReturnTermClasses}>24h</span>}
                </dt>
              </div>
            ) : null}
          </div>
          {loading ? null : <Icon icon={ChevronRightIcon} className="text-heading-content" />}
        </div>
      </div>
    </VaultCard.Wrapper>
  );
}

interface VaultCardDivWrapperProps {
  children: ReactNode;
  className?: string;
  loading?: boolean;
}

interface VaultCardLinkWrapperProps extends LinkProps {
  children: ReactNode;
  className?: string;
  loading?: boolean;
}

type VaultCardWrapperProps = VaultCardDivWrapperProps | VaultCardLinkWrapperProps;

function VaultCardWrapper({ className, children, loading = false, ...props }: VaultCardWrapperProps) {
  if (loading || !("to" in props)) {
    return <div className={className}>{children}</div>;
  }

  const classes = classNames(
    "group h-full focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-base-100 transition",
    className,
  );

  return (
    <Link className={classes} {...props}>
      {children}
    </Link>
  );
}

VaultCard.Wrapper = VaultCardWrapper;
